import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { GiOpenChest, GiLockedChest } from "react-icons/gi";
import { BiHomeAlt } from "react-icons/bi";
import { GoInfo } from "react-icons/go";
import {
  MdOutlineWorkOutline,
  MdOutlineConnectWithoutContact,
} from "react-icons/md";
import { RiUserSettingsFill } from "react-icons/ri";

import "./Header.scss";
import images from "../../assets/images";
import { FaWhatsapp } from "react-icons/fa";

const Header = () => {
  const [menu, setMenu] = useState(false);

  const container = {
    hidden: {
      opacity: 0,
      right: -20,
    },
    show: {
      opacity: 1,
      right: menu ? 0 : -20,
      width: menu ? "fit-content" : "0vw",
      transition: {
        when: menu === true,
        staggerChildren: 0.3,
      },
    },
  };

  const items = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
    },
  };

  const itemsNav = [
    { name: "home", icon: <BiHomeAlt /> },
    { name: "about", icon: <GoInfo /> },
    { name: "skills", icon: <RiUserSettingsFill /> },
    { name: "work", icon: <MdOutlineWorkOutline /> },
    { name: "contact", icon: <MdOutlineConnectWithoutContact /> },
  ];

  const [scrollStyle, setScrollStyle] = useState({
    padding: "2.4rem 3rem",
    backdropFilter: "blur(0px)",
  });

  const handleScroll = () => {
    window.scrollY > 10
      ? setScrollStyle({
          padding: ".5rem 2rem 0",
          backdropFilter: "blur(5px)",
          boxShadow: "0 0 20px rgba(255, 255, 255, 0.25)",
        })
      : setScrollStyle({
          padding: "2.4rem 3rem",
          backdropFilter: "blur(0px)",
        });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="app__header" style={scrollStyle}>
      <div className="app__header__logo">
        <div className="logo">
          <a href="#home">
            <img src={images.myLogo} alt="rabia-loukhai" loading="lazy" />
            
          </a>
        </div>
      </div>
      <nav className="app__header__nav">
        <div className="app__header__nav-links ">
          <ul className="links app__flex">
            {itemsNav.map((item, indx) => (
              <motion.li
                whileHover={{ scale: 1.2 }}
                className="link"
                key={item.name + indx}
              >
                <a href={`#${item.name}`}>{item.name}</a>
                <div />
              </motion.li>
            ))}
          </ul>
        </div>
      </nav>

      <div className="app__header-menu">
        {/* <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          class="svg-setting"
          style={{ display: "none" }}
        >
          <defs>
            <linearGradient id="Gradient1">
              <stop class="stop1" offset="0%" />
              <stop class="stop2" offset="50%" />
              <stop class="stop3" offset="100%" />
            </linearGradient>
            <linearGradient id="Gradient2" x1="0" x2="0" y1="0" y2="1">
              <stop offset="0%" stopColor="pink" />
              <stop offset="50%" stopColor="purple" stopOpacity="30" />
              <stop offset="150%" stopColor="blue" />
            </linearGradient>
          </defs>
        </svg> */}
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          // style={{ display: "none" }}
          className="svg-setting"
        >
          <defs>
            <linearGradient id="Gradient1">
              <stop offset="0%" stopColor="#e6a3ae" />
              <stop offset="50%" stopColor="#921592" />
              <stop offset="100%" stopColor="#d118c7" />
            </linearGradient>
            <linearGradient id="Gradient2" gradientTransform="rotate(90)">
              <stop offset="5%" stopColor="gold" />
              <stop offset="95%" stopColor="red" />
            </linearGradient>
          </defs>
        </svg>

        {menu ? (
          <GiOpenChest
            className="app__header-menu-icons"
            onClick={() => setMenu(false)}
          />
        ) : (
          <GiLockedChest
            className="app__header-menu-icons menu-icon"
            onClick={() => setMenu(true)}
          />
        )}

        <motion.div
          className="app__header-menu-links"
          variants={container}
          initial="hidden"
          animate="show"
          transition={{ type: "spring", bounce: 0.45 }}
        >
          {itemsNav.map((item, indx) => (
            <motion.li
              variants={items}
              whileHover={{ scale: 1.0991 }}
              className="link app__flex"
              key={indx}
            >
              <a href={`#${item.name}`} onClick={() => setMenu(false)}>
                {item.icon} {item.name}
              </a>
            </motion.li>
          ))}
          <motion.li whileHover={{ scale: 1.1 }} key="whtsp" className="link">
            <a
              href="https://wa.me/212654142135?text=Sallam%20Alikom%20Rabi3"
              target="_blank"
              rel="noreferrer"
              className="whtsp"
            >
              <FaWhatsapp />
              whtsp
            </a>
          </motion.li>
        </motion.div>
      </div>
    </div>
  );
};

export default Header;
