import React from "react";
import { FaFacebook, FaGithub, FaTwitter, FaWhatsapp } from "react-icons/fa";

import "./Social.scss";
const SocialMedia = () => {
  return (
    <div className="app__social">
      <div>
        <a
          href="https://www.facebook.com/l.rb.dev"
          rel="noreferrer"
          target="_blank"
          className="fb"
        >
          <FaFacebook />
        </a>
      </div>
      <div>
        <a
          href="https://github.com/Loukhai"
          target="_blank"
          rel="noreferrer"
          className="git"
        >
          <FaGithub />
        </a>
      </div>
      <div>
        <a
          href="https://twitter.com/loukhai_rabia"
          target="_blank"
          rel="noreferrer"
          className="twt"
        >
          <FaTwitter />
        </a>
      </div>
      <div>
        <a
          href="https://wa.me/212654142135?text=Sallam%20Alikom%20Rabi3"
          target="_blank"
          rel="noreferrer"
          className="whtsp"
        >
          <FaWhatsapp />
        </a>
      </div>
    </div>
  );
};

export default SocialMedia;
