// import email from "./images/email.png";
// import mobile from "./images/mobile.png";
// import api from "./images/api.png";
// import cpp from "./images/cpp.png";
// import css from "./images/css.png";
// import figma from "./images/figma.png";
// import flutter from "./images/flutter.png";
// import git from "./images/git.png";
// import graphql from "./images/graphql.png";
// import html from "./images/html.png";
// import javascript from "./images/javascript.png";
// import mu5 from "./images/mu5.png";
// import node from "./images/node.png";
// import python from "./images/python.png";
// import react from "./images/react.png";
// import redux from "./images/redux.png";
// import sass from "./images/sass.png";
// import typescript from "./images/typescript.png";
// import vue from "./images/vue.png";
import about01 from "./images/about01.png";
// import about02 from "./images/about02.png";
// import about03 from "./images/about03.png";
import about04 from "./images/about04.png";

// import profile from "./images/profile.png";
// import circle from "./images/circle.svg";
// import logo from "./images/logo.png";

// import adidas from "./images/adidas.png";
// import amazon from "./images/amazon.png";
// import asus from "./images/asus.png";
// import bolt from "./images/bolt.png";
// import nb from "./images/nb.png";
// import skype from "./images/skype.png";
// import spotify from "./images/spotify.png";

// import logo_lines_2 from "./images/logo-lines-2.svg";
// import logo_lines from "./images/logo-lines.svg";
// import logo_s from "./images/logo-s.png";
// import logo1 from "./images/logo1.png";
// import logo2 from "./images/logo2.png";
// import logo3 from "./images/logo3.png";
// import logo4 from "./images/logo4.png";
// import logo_sub from "./images/logo_sub.png";
// import logopreload from "./images/logopreload.png";

import myLogo from "./images/myLogo.svg";
import myImg from "./images/rabia_loukhai_img2.jpg";
// import heroBg from "./images/hero-background.webp";

// import backend1 from "./images/backend1.gif";
import backend2 from "./images/backend2.gif";
import frontend from "./images/frontend.gif";
// import frontendsd from "./images/frontend-sd.gif";
// import frontend2 from "./images/frontend 2.png";
// import fullstack from "./images/fullstack.gif";
import workfun from "./images/Work-Fun.gif";

//project images
import pr_springHotels from "./images/pr_springhotels.herokuapp.com.jpeg";
import pr_rabiaTasty from "./images/pr_rabia-tasty.vercel.app.jpeg";
import pr_rbFood from "./images/pr_rbfood.vercel.app.jpeg";

export default {
  //   email,
  //   mobile,
  //   api,
  //   cpp,
  //   css,
  //   figma,
  //   flutter,
  //   git,
  //   graphql,
  //   html,
  //   javascript,
  //   mu5,
  //   node,
  //   python,
  //   react,
  //   redux,
  //   sass,
  //   typescript,
  //   vue,

  about01,
  //   about02,
  //   about03,
  about04,
  //   profile,
  //   circle,
  //   logo,
  //   adidas,
  //   amazon,
  //   asus,
  //   bolt,
  //   nb,
  //   skype,
  //   spotify,

  //   logo1,
  //   logo2,
  //   logo3,
  //   logo4,
  //   logo_lines,
  //   logo_lines_2,
  //   logo_s,
  //   logo_sub,
  //   logopreload,

  myLogo,
  myImg,
  //   heroBg,

  //   backend1,
  backend2,
  frontend,
  //   frontendsd,
  //   frontend2,
  //   fullstack,
  workfun,
  pr_springHotels,
  pr_rabiaTasty,
  pr_rbFood,
};
