import React, { useEffect } from "react";
import { images } from "../../assets";
import { SocialMedia } from "../../components";
import { motion } from "framer-motion";

import "./hero.scss";
import { headerAnimation } from "../../Animation/Animation";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Hero = () => {
  const vh = window.innerHeight * 0.01;
  // console.log(vh);
  // const [vh, setVh] = useState(window.innerHeight * 0.01);
  useEffect(() => {
    window.addEventListener("resize", () => {
      // setVh(window.innerHeight * 0.01);
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });

    return () => {
      window.removeEventListener("resize", () => {
        document.documentElement.style.setProperty("--vh", `${vh}px`);
      });
    };
  });

  return (
    <motion.div animate={headerAnimation} id="home" className="app__hero">
      <div className="app__hero-bg">
        {/* <LazyLoadImage
          PlaceholderSrc={images.imageHeroOptimized}
          src={images.imageHero}
          effect="blur"
          height="100%"
          width="100%"
          alt="hero-bg"
        /> */}
      </div>
      {/* just sup */}

      <SocialMedia />

      <div className="app__hero-hello">
        <div className="app__hero-infos">
          <h4> -- HELLO -- </h4>
          <h1>
            I'm <span>RABIE</span> LOUKHAI
          </h1>
          <p className="p-text">
            Drive Your Business Forward with <br /> Powerful{" "}
            <strong>Web Development</strong> Products.
            {/* I believe, My Clients deserve to be remarkable
            in their business. I develope the products people appreciate all
            around the world. */}
          </p>
        </div>
        <div className="app__hero-img app__flex">
          <img src={images.myLogo} alt="rabia_loukhai" loading="lazy" />
        </div>
        <SocialMedia />
      </div>
      <div className="app__hero-scroll app__flex">
        <div className="app__flex">
          <div>Scroll Down</div>
          <span>&#10230;</span>
        </div>
      </div>
    </motion.div>
  );
};

export default Hero;
