import {
  SiExpress,
  SiHtml5,
  SiJavascript,
  SiMongodb,
  SiNodedotjs,
  SiReact,
  SiSass,
  SiCsharp,
  SiDotnet,
} from "react-icons/si";
export const skillsArr = [
  {
    icon: <SiHtml5 />,
    title: "HTML5",
    desc: "The Standard Markup Language for Web Pages.",
    clr: "#e34f26",
  },
  {
    icon: <SiSass />,
    title: "SCSS",
    desc: "The Language I use to Style an HTML Document, CSS with SuperPowers",
    clr: "#CF649A",
  },
  {
    icon: <SiJavascript />,
    title: "JAVASCRIPT",
    desc: "The Programming Language of the Web.",
    clr: "#f7df1e",
  },
  {
    icon: <SiReact />,
    title: "REACT JS",
    desc: "My prefer Framwork to Building User Interfaces specifically for SPA.",
    clr: "#00d8ff",
  },
  {
    icon: <SiNodedotjs />,
    title: "NODEJS",
    desc: "BACK-END JavaScript Runtime Environment.",
    clr: "#44883e",
  },
  {
    icon: <SiMongodb />,
    title: "MONGODB",
    desc: "The Leading Modern, General purpose Database Platform.",
    clr: "#589636",
  },
  {
    icon: <SiExpress />,
    title: "EXPRESS",
    desc: "The Fast, Unopinionated, Minimalist Web Framework for NODEJS.",
    clr: "#4d4f53",
  },
  {
    icon: <SiCsharp />,
    title: "CSharp",
    desc: "Beginner in C#",
    clr: "#b32bee",
  },

  {
    icon: <SiDotnet />,
    title: ".NetCore",
    desc: "Beginner in DotNetCore 6",
    clr: "#783bd2",
  },
];
