import React, { useState } from "react";
import { BiUpArrow } from "react-icons/bi";

import "./ScrollToTop.scss";
const ScrollTop = () => {
  const [visible, setVisible] = useState(false);
  const handleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0 });
  };
  window.addEventListener("scroll", handleVisible);

  return (
    <div>
      {visible && (
        <div className="scrollToTop" onClick={scrollToTop}>
          <BiUpArrow />
        </div>
      )}
    </div>
  );
};

export default ScrollTop;
