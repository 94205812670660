export const headerAnimation = {
  hidden: { y: -100, opacity: 0 },
  show: { y: 0, opacity: 1 },
};

export const imageAnimation = {
  hidden: { scale: 2, opacity: 0 },
  show: { scale: 1, opacity: 1 },
};

export const navbarAnimation = {
  hidden: { y: -100 },
  show: { y: 0 },
};

export const contrNavbarAnimation = {
  hidden: { y: 100 },
  show: { y: 0 },
};

export const revealLeft = {
  hidden: { x: -1000 },
  show: { x: 0 },
};

export const revealRight = {
  hidden: { x: 1000 },
  show: { x: 0 },
};

export const cardAnimation = {
  hidden: { scale: 0, opacity: 0 },
  show: { scale: 1, opacity: 1 },
};

export const topContainerAnimation = {
  hidden: { scale: 0, opacity: 0 },
  show: { scale: 1, opacity: 1 },
};

export const reasonsAnimation = {
  hidden: { scale: 0, opacity: 0 },
  show: { scale: 1, opacity: 1 },
};

export const letterAnimation = {
  hidden: { x: -100, opacity: 0 },
  show: { x: 0, opacity: 1 },
};

export const text = {
  hidden: { y: 0 },
  show: {
    y: 0,
    transition: {
      delayChildren: 0.6,
      staggerChildren: 0.04,
      stagerDircetion: -1,
    },
  },
};

export const letter = {
  hidden: {
    y: 400,
  },
  show: {
    y: 0,
    transition: { duration: 1, ease: [0.6, 0.01, 0.05, 0.9] },
  },
};
