import { images } from "../../assets";

const aboutSkills = [
  {
    srcImg: images.frontend,
    title: "FRONT-END",
    text: "Responsive UI, Animations & cool Stuff",
  },
  {
    srcImg: images.backend2,
    title: "BACK-END",
    text: "Database, API & Authentication",
  },
];

export default aboutSkills;
