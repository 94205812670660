import React, { useState, useRef } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { motion } from "framer-motion";
import {
  cardAnimation,
  letterAnimation,
  revealLeft,
} from "../../Animation/Animation";
import "./Contact.scss";
import { useScroll } from "../../Animation";
import emailjs from "@emailjs/browser";

const Result = () => {
  return (
    <p className="p-text ok-send">
      Your Message has been <b>successfuly sent</b>. i will contact you soon
    </p>
  );
};

const Contact = () => {
  const [element, controls] = useScroll();
  const [result, setResult] = useState(false);

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID, // EMAILJS_SERVICE_ID
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID, // EMAILJS_TEMPLATE_ID
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY // EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log(result.status);
          if (result.text === "OK") setResult(true);
        },
        (error) => {
          console.log(error.text);
        }
      );

    form.target.reset();
  };

  return (
    <motion.div
      ref={element}
      transition={{ staggerChildren: 0.5, duration: 0.7 }}
      id="contact"
      className="app__container app__contact"
    >
      <motion.div
        variants={letterAnimation}
        animate={controls}
        transition={{ duration: 1, ease: [0.6, 0.01, 0.05, 0.9] }}
        className="title-container"
      >
        Contact Me
      </motion.div>
      <div className="app__contact-info ">
        <motion.div
          animate={controls}
          variants={revealLeft}
          transition={{
            ease: [0.6, 0.01, -0.05, 0.95],
            delay: 0.1,
          }}
          className="app__contact-infos"
        >
          <div className="infos-p">
            I am open to your ideas, questions and needs. My clients get
            superior results when a short-term acquaintance turns into a
            <strong> long-term collaboration. </strong>
          </div>
          <div className="infos-te">
            <a
              href="https://wa.me/212654142135?text=Sallam%20Alikom%20Rabi3"
              className="infos-tel"
            >
              <FaWhatsapp /> <strong> 00 2126 541 421 35</strong>
            </a>

            <a href="mailto:loukhairabia@gmail.com" className="infos-email">
              <MdOutlineEmail /> <b> loukhairabia@gmail.com</b>
            </a>
          </div>
        </motion.div>
        <motion.div
          variants={cardAnimation}
          animate={controls}
          transition={{
            ease: [0.6, 0.01, -0.05, 0.95],
            duration: 1,
            delay: 0.11,
            stiffness: 300,
          }}
          className="app__contact-arrow"
        >
          {" "}
          &#10230;{" "}
        </motion.div>
        {/* <motion.div */}{" "}
        {/*err founded when we need to write in form contact in mobile 
                                 the keyboard up but the form it disappear*/}
        <div
          // variants={cardAnimation}
          // animate={controls}
          // transition={{
          //   ease: [0.6, 0.01, -0.05, 0.95],
          //   duration: 1,
          //   delay: 0.26,
          //   stiffness: 300,
          // }}
          className="app__contact-mailing"
        >
          <div className="mailing-forms">
            <form ref={form} onSubmit={sendEmail}>
              <div className="inp-name">
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="name input"
                  autoComplete="off"
                  required
                />
                <label htmlFor="name" className="title-text label-name">
                  <span>Your Name</span>
                </label>
              </div>

              <div className="inp-email">
                <input
                  type="email"
                  name="email"
                  id="email input"
                  autoComplete="off"
                  required
                />
                <label htmlFor="email" className="title-text">
                  <span>Mail Address</span>
                </label>
              </div>

              <div className="textarea">
                <textarea
                  className="input"
                  name="desc"
                  id="desc"
                  rows={9}
                  required
                ></textarea>
                <label htmlFor="desc" className="title-text">
                  <span>Project Description</span>
                </label>
              </div>

              <button className="btn contact-btn">SEND</button>
              <div> {result ? <Result /> : null}</div>
            </form>
          </div>
          {/* </motion.div> */}
        </div>
      </div>
    </motion.div>
  );
};

export default Contact;
