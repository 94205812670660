import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Mousewheel } from "swiper";
import { motion } from "framer-motion";
import { cardAnimation } from "../../Animation/Animation";
import { useScroll } from "../../Animation";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import "./Skills.scss";
import { skillsArr } from "./data";

const Skills = () => {
  const [element, controls] = useScroll();

  return (
    <motion.div
      ref={element}
      id="skills"
      className="app__container app__skills"
    >
      <motion.div
        variants={cardAnimation}
        animate={controls}
        transition={{ delay: 0.15 }}
        className="app__skills-text"
      >
        <div className="title-container">Expertise Area</div>
        <div className="p-text">
          ReactJS/Web Components, Brackets extensions and everything else
          accomplished on my spare software development time specially for a
          devloper <b>MERN STACK</b>.
        </div>
      </motion.div>
      <Swiper
        mousewheel={{ forceToAxis: true }}
        slidesPerView={1}
        spaceBetween={25}
        breakpoints={{
          600: {
            slidesPerView: 2,
          },
          700: {
            slidesPerView: 3,
          },
          1600: {
            slidesPerView: 4,
          },
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Navigation, Pagination, Scrollbar, A11y, Mousewheel]}
        // onSlideChange={() => console.log("slide change")}
        // onSwiper={(swiper) => console.log(swiper)}
      >
        {skillsArr.map((skl, indx) => (
          <SwiperSlide key={indx}>
            <motion.div
              className="swiper-slide-content"
              variants={cardAnimation}
              animate={controls}
              transition={{ delay: 0.2, delayChildren: 0.4 }}
            >
              <div className="title-slide" style={{ "--clr-hover": skl.clr }}>
                {skl.icon}
                <div>{skl.title}</div>
              </div>
              <div className="p-text">{skl.desc}</div>
            </motion.div>
          </SwiperSlide>
        ))}
      </Swiper>
    </motion.div>
  );
};

export default Skills;
