import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import React from "react";
import { createRoot } from "react-dom/client";
//Components:
import App from "./App";

//style:
import "./index.css";

if (process.env.REACT_APP_NODE_ENV === "production") {
  disableReactDevTools();
}

const container = document.getElementById("app");
const root = createRoot(container);

const Index = () => {
  return (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
};

root.render(<Index />);
