import React, { useState } from "react";
import works from "./data";
import { motion } from "framer-motion";
import { useScroll } from "../../Animation";
import {
  headerAnimation,
  topContainerAnimation,
} from "../../Animation/Animation";

import "./Work.scss";
import { images } from "../../assets";

const Work = () => {
  const arrWork = [
    "All",
    "Web App",
    "Mobile App",
    // "React Js",
    // , "UI/UX"
  ];
  const [activeItem, setActiveItem] = useState("All");
  const [filterWork, setFilterWork] = useState(works);

  const [indcStyle, setIndcStyle] = useState({
    width: "",
    height: "",
    left: "",
    top: "",
  });

  const handleMouseOver = (ev) => {
    setIndcStyle({
      width: `${ev.target.offsetWidth}px`,
      height: `${ev.target.offsetHeight}px`,
      left: `${ev.target.offsetLeft}px`,
      top: `${ev.target.offsetTop}px`,
    });
  };

  function handleFilter(item) {
    setActiveItem(item);

    if (item !== "All") {
      setFilterWork(
        works.filter((workFiltred) => workFiltred.type.includes(item))
      );
    } else {
      setFilterWork(works);
    }
  }

  // let style = { opacity: 1, transform: "translateX(0px) translateZ(0px)" };

  const [element, controls] = useScroll();

  return (
    <motion.div
      ref={element}
      id="work"
      className="app__container app__work app__flex"
    >
      <motion.div
        variants={headerAnimation}
        animate={controls}
        transition={{ duration: 1, ease: [0.6, 0.01, 0.05, 0.9] }}
        className="app__work-text app__flex"
      >
        <div className="title-container">Featured Works</div>
        <div className="p-text">
          From Web Components and UI/UX animations to React.JS, Redux, and
          Node.JS. Check out my latest web software development portfolio
          projects.
        </div>
      </motion.div>
      <motion.div
        variants={headerAnimation}
        animate={controls}
        transition={{
          delay: 0.1,
          duration: 1,
          ease: [0.6, 0.01, 0.05, 0.9],
        }}
        className="app__work-filter app__flex"
      >
        {arrWork.map((wrk, indx) => (
          <motion.div
            variants={topContainerAnimation}
            animate={controls}
            transition={{
              staggerChildren: 2,
              delay: 0.12,
              duration: 1,
              ease: [0.6, 0.07, 0.015, 0.9],
            }}
            key={indx}
            onMouseOver={handleMouseOver}
            className={`app__flex item ${wrk === activeItem && "item-active"}`}
            onClick={() => handleFilter(wrk)}
          >
            {wrk}
          </motion.div>
        ))}
        <motion.div
          variants={topContainerAnimation}
          animate={controls}
          transition={{ ease: [0.6, 0.01, 0.05, 0.9] }}
          className="indicator"
          style={indcStyle}
        >
          ...
        </motion.div>
      </motion.div>

      <motion.div
        variants={topContainerAnimation}
        animate={controls}
        transition={{
          staggerChildren: 0.2,
          delay: 0.1,
          duration: 2,
          ease: [0.6, 0.07, 0.015, 0.9],
        }}
        className="app__work-works app__flex"
      >
        {filterWork.map((wrk, indx) =>
          wrk.data.length === 0 ? (
            <motion.div className="wrapper-work app__flex" key={indx}>
              <div className="work-img-zero">
                <img
                  src={images.workfun}
                  alt="fun happy boy comming soon"
                  loading="lazy"
                />
                <div className="work-type">{wrk.type[0]}</div>
              </div>
              <div className="work-content">
                <div className="title-text">Coming Soon!</div>
                <div className="p-text">I Just Need Time To Do it.</div>
              </div>
            </motion.div>
          ) : (
            wrk.data.map((data, indx) => (
              <motion.div
                // variants={topContainerAnimation}
                // animate={controls}
                // transition={{
                //   delay: 0.5,
                //   duration: 0.7,
                //   ease: [0.6, 0.01, 1.05, 0.4],
                // }}
                className="wrapper-work app__flex"
                key={indx}
              >
                <div className="work-img">
                  <img
                    src={data.srcImg}
                    alt={data.title + indx}
                    loading="lazy"
                  />
                  <div className="work-type">{wrk.type[0]}</div>
                </div>
                <div className="work-content">
                  <span>
                    <div className="title-text">{data.title}</div>
                    <a className="p-text" href={data.url}>
                      {data.url}
                    </a>
                  </span>
                  <div className="p-text">{data.text}</div>
                </div>
              </motion.div>
            ))
          )
        )}
      </motion.div>
    </motion.div>
  );
};

export default Work;
