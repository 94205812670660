import { images } from "../../assets";

const works = [
  {
    type: ["Web App", "All"],
    data: [
      {
        srcImg: images.pr_springHotels,
        title: "SpringHotels",
        text: "Web Page for Reservation Hotels.",
        // text: "NodeJs/MonogoDB/ReactJs",
        url: "https://springhotels.herokuapp.com",
      },
      {
        srcImg: images.pr_rabiaTasty,
        title: "RbTasty",
        text: "Delivery and Orders a Tasty Foods",
        url: "https://rabia-tasty.vercel.app",
      },
      {
        srcImg: images.pr_rbFood,
        title: "RbFood",
        text: "Reservation foods and Delivery",
        url: "https://rbfood.vercel.app",
      },
    ],
  },
  // {
  //   type: ["React Js", "All"],
  //   data: [
  //     {
  //       srcImg: images.about04,
  //       title: "Lorem, react js",
  //       text: "Lorem, react Lorem, ipsum",
  //     },
  //   ],
  // },

  {
    type: ["Mobile App", "All"],
    data: [],
  },
  // {
  //   type: ["UI/UX", "All"],
  //   data: [],
  // },
];

export default works;
