import React from "react";
import "./Footer.scss";

const Footer = () => {
  return (
    <div className=" p-text app__footer">
      Copyright &copy;{new Date().getFullYear()} All Rights Reserved.
      <br />
      created by
      <span>
        <a href="#about"> Rabiâ_Loukhai </a>
      </span>
    </div>
  );
};

export default Footer;
