import React from "react";
import { motion } from "framer-motion";
import aboutSkills from "./data";
import { useScroll } from "../../Animation";
import { images } from "../../assets";
import { cardAnimation, letter, revealLeft } from "../../Animation/Animation";

import "./About.scss";

const About = () => {
  const [element, controls] = useScroll();

  return (
    <div
      ref={element}
      id="about"
      className="app__container app__flex app__about"
    >
      <motion.div className="app__about-me app__flex">
        <motion.div
          animate={controls}
          variants={revealLeft}
          transition={{
            delay: 0.05,
            ease: [0.6, 0.01, -0.05, 0.95],
            type: "tween",
          }}
          className="about-me-img"
        >
          <img src={images.myImg} alt="rabia_loukhai" loading="lazy" />
        </motion.div>
        <div className="about-me-infos ">
          <motion.div
            animate={controls}
            variants={revealLeft}
            transition={{
              delay: 0.1,
              type: "tween",
              ease: [0.6, 0.01, -0.05, 0.95],
              //  stiffness: 300,
            }}
            // animate={controls}
            // variants={text}
            className="title-container"
          >
            <motion.span variants={letter}>&#9472;&#9472;</motion.span>
            <motion.span variants={letter}> </motion.span>
            <motion.span variants={letter}>A</motion.span>
            <motion.span variants={letter}>b</motion.span>
            <motion.span variants={letter}>o</motion.span>
            <motion.span variants={letter}>u</motion.span>
            <motion.span variants={letter}>t</motion.span>
            <motion.span variants={letter}> </motion.span>
            <motion.span variants={letter}>M</motion.span>
            <motion.span variants={letter}>e</motion.span>
          </motion.div>
          <motion.p
            animate={controls}
            variants={revealLeft}
            transition={{
              delay: 0.15,
              ease: [0.6, 0.01, -0.05, 0.95],
              stiffness: 300,
            }}
            className="p-text"
          >
            I am a <i>FULL-STACK</i> developer I build immersive user
            experience. I create an exceptional visualization and thought-out
            functionality.
          </motion.p>
          <motion.div
            animate={controls}
            variants={revealLeft}
            transition={{
              delay: 0.19,
              ease: [0.6, 0.01, -0.05, 0.95],
              stiffness: 300,
            }}
            className="p-text quote"
          >
            <q>
              <p>Good Business Good Interfaces</p>
            </q>
          </motion.div>
          <motion.div
            animate={controls}
            variants={revealLeft}
            transition={{
              delay: 0.123,
              ease: [0.6, 0.01, -0.05, 0.95],
              stiffness: 300,
            }}
            className="about-btn"
          >
            <a href="#contact">
              <button className="btn app__flex">HIRE ME</button>
            </a>
          </motion.div>
        </div>
      </motion.div>
      <motion.div ref={element} className="app__about-skills">
        <motion.div
          animate={controls}
          variants={revealLeft}
          transition={{
            delay: 0.1,
            ease: [0.65, 0, 0.35, 1],
            stiffness: 300,
          }}
          className="about-skills-vl title-container"
        >
          <span>&#9472;&#9472;</span> My Skills
        </motion.div>

        <motion.div
          animate={controls}
          variants={cardAnimation}
          transition={{ delay: 0.5, stiffness: 5000 }}
          className="about-skills"
        >
          {aboutSkills.map((aboutSkill, indx) => (
            <motion.div
              transition={{ stiffness: 5000 }}
              className="about-skills-wrapper"
              key={indx}
            >
              <img src={aboutSkill.srcImg} alt="about01" loading="lazy" />
              <div className="about-skills-title title-text">
                {aboutSkill.title}
              </div>
              <div className="about-skills-txt p-text">{aboutSkill.text} </div>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>
    </div>
  );
};

export default About;
