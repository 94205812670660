import React from "react";

import "./App.scss";
import { Footer, Header, ScrollToTop } from "./components/";
import { About, Contact, Hero, Skills, Work } from "./container/";
import { motion } from "framer-motion";

const App = () => {
  return (
    <motion.div className="app">
      <Header />
      <ScrollToTop />
      <Hero />
      <About />
      <Skills />
      <Work />
      <Contact />
      <Footer />
    </motion.div>
  );
};

export default App;
